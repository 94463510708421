﻿let searchHistory = {
	options: {
		storageName: 'tum-searchHistory'
	},
	_get: function () {
		let data = localStorage.getItem(this.options.storageName);
		return data ? JSON.parse(data) : [];
	},
	get: function(){
		if (!this.isSupported()) return [];
		let data = this._get();
		return data.reverse();
	},
	add: function (query, type) {
		if (!this.isSupported()) return;
		if (!query || !type) return;
		let history = this._get();

		if (history.some(e => e.query == query && e.type == type)) return;

		if (history.length > 3) {
			history.splice(0, 1);
		}

		history.push({ query, type });
		localStorage.setItem(this.options.storageName, JSON.stringify(history));
	},
	clear: function () {
		if (!this.isSupported()) return;
		localStorage.removeItem(this.options.storageName);
	},
	isSupported: function(){
		var test = 'test';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			return true;
		} catch {
			return false;
		}
	}
};

export default searchHistory;